import React, { useState, useEffect } from "react"
import {graphql, Link} from 'gatsby'
import Layout from "../components/layout/layout"
import Grid, {Col, Row} from "../components/grid/grid";
import Img from "gatsby-image";
import SEO from "../components/seo";
import styled from "styled-components";
import {useMediaQuery} from "react-responsive";

type ProjectProps = {
  data: any
}

const ProjectProperty = styled.p`
  font-weight: 300;
  margin: 0;
  font-size: 14px;
`

const ProjectName = styled.h4`
  font-weight: 300;
  margin-bottom: 20px;
  margin-top: 25px;
  text-transform: uppercase;
`

const StyledCol = styled(Col)`
  margin-bottom: 30px;
`
const StyledGrid = styled(Grid)`
  padding-top: 30px;
  padding-bottom: 30px;

   @media (min-width: 960px) {
      padding-left: 0;
      padding-right: 0;
      padding-top: 60px;
   }
`

const StyledImage = styled(Img)`
  transform: scale(1);
  transition: .2s transform ease-in-out;
  cursor: pointer;

  @media (min-width: 960px) {
    &:hover {
      transform: scale(1.05)
    }
 }
`

const StyledColMobile = styled(StyledCol)`
  padding: 0;
  margin-top: 15px;
  margin-bottom: 0;
`

const BackIcon = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 512.001 512.001" xmlSpace="preserve">
    <g>
      <g>
        <path d="M384.834,180.699c-0.698,0-348.733,0-348.733,0l73.326-82.187c4.755-5.33,4.289-13.505-1.041-18.26
          c-5.328-4.754-13.505-4.29-18.26,1.041l-82.582,92.56c-10.059,11.278-10.058,28.282,0.001,39.557l82.582,92.561
          c2.556,2.865,6.097,4.323,9.654,4.323c3.064,0,6.139-1.083,8.606-3.282c5.33-4.755,5.795-12.93,1.041-18.26l-73.326-82.188
          c0,0,348.034,0,348.733,0c55.858,0,101.3,45.444,101.3,101.3s-45.443,101.3-101.3,101.3h-61.58
          c-7.143,0-12.933,5.791-12.933,12.933c0,7.142,5.79,12.933,12.933,12.933h61.58c70.12,0,127.166-57.046,127.166-127.166
          C512,237.745,454.954,180.699,384.834,180.699z"/>
      </g>
    </g>
    </svg>
)

const BackLink = styled(Link)`
  svg {
    width: 20px;
  }
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 0;
    }
`

const StyledModal = styled.div<{ isOpen: boolean}>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding: 60px;
  top: ${({isOpen}) => isOpen ? '0' : '100%'};
  left: 0;
  transition: .5s all ease-in-out;
  opacity: ${({ isOpen }) => isOpen ? '1' : '0'};
  z-index: 100;
`

const StyledModalContainer = styled.div`
  top: 50%;
  max-width: 1280px;
  margin: 0 auto;
  transform: translateY(-50%);
  position: relative;
`

const Circle = styled.div<{ isActive: boolean}>`
    width: 10px;
    height: 10px;
    background-color: ${({ isActive }) => isActive ? 'black' : '#ebedee'};
    border-radius: 10px;
    margin: 0 15px;
    cursor: pointer;

    @media (min-width: 960px) {
      transition: .2s transform ease-in-out;

      &:hover {
        transform: scale(1.1)
      }
    }
`

const CloseGallery = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 512 512" xmlSpace="preserve">
    <g>
      <g>
        <g>
          <path d="M256,0C114.844,0,0,114.844,0,256s114.844,256,256,256s256-114.844,256-256S397.156,0,256,0z M256,490.667
            C126.604,490.667,21.333,385.396,21.333,256S126.604,21.333,256,21.333S490.667,126.604,490.667,256S385.396,490.667,256,490.667
            z"/>
          <path d="M359.542,152.458c-4.167-4.167-10.917-4.167-15.083,0L256,240.917l-88.458-88.458c-4.167-4.167-10.917-4.167-15.083,0
            c-4.167,4.167-4.167,10.917,0,15.083L240.917,256l-88.458,88.458c-4.167,4.167-4.167,10.917,0,15.083
            c2.083,2.083,4.813,3.125,7.542,3.125s5.458-1.042,7.542-3.125L256,271.083l88.458,88.458c2.083,2.083,4.813,3.125,7.542,3.125
            c2.729,0,5.458-1.042,7.542-3.125c4.167-4.167,4.167-10.917,0-15.083L271.083,256l88.458-88.458
            C363.708,163.375,363.708,156.625,359.542,152.458z"/>
        </g>
      </g>
    </g>
  </svg>
)

const StyledClose = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: -15px;
  cursor: pointer;
  right: -45px;
`

const CircleContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px;
  background: #fff;

  svg {
    width: 25px;
    height: 25px;
    position: relative;
    top: -8px;
    margin: 0 15px;
    cursor: pointer;
  }
`

const ModalImage = styled.div<{ isOpen: boolean, isActive: boolean}>`
  height: 80vh;
  margin: 0 auto;
  transition: all .3s ease-in .3s;
  width: 100%;
  transform: scale(${({isActive}) => isActive ? '1' : '.8'});

  img {
    width: auto !important;
    transform: translateX(-50%);
    left: 50% !important;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: .8;
`

const GalleryBackIcon = ({ onClick }: any) => (
<svg onClick={onClick} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 477.175 477.175"  xmlSpace="preserve">
  <g>
    <path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225
      c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"/>
  </g>
</svg>
)

const GalleryNextIcon = ({ onClick }: any) => (
  <svg onClick={onClick} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 477.175 477.175" xmlSpace="preserve">
    <g>
      <path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5
        c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z
        "/>
    </g>
  </svg>
);

const GalleryContainer = styled.div<{ count: number, index: number }>`
  display: flex;
  width: ${({ count }) => count * 100}%;
  transform: translateX(${({ index, count }) => -index * (100 / count)}%);
  transition: transform .5s ease-in-out;
;`



const Modal = ({ isOpen, index, onClose, images, onSelect }: any) => {
  return (
      <StyledModal isOpen={isOpen}>
        {isOpen && <Overlay />}
        <StyledModalContainer>
          <GalleryContainer index={index} count={images.length}>
            {images.map((image: any, id: number) => {
              return (<ModalImage key={id} isActive={id === index} isOpen={isOpen} onClick={onClose}><Img style={{ height: '80vh' }} fluid={image.fluid} /></ModalImage>)
            })}
          </GalleryContainer>
          <CircleContainer>
            <GalleryBackIcon onClick={() => index > 0 && onSelect(index - 1)} />
            {images.map((_imageItem: any, i: number) => <Circle onClick={() => onSelect(i)} key={i} isActive={index === i} />)}
            <GalleryNextIcon onClick={() => index < images.length - 1 && onSelect(index + 1)} />
          </CircleContainer>
          <StyledClose onClick={onClose}><CloseGallery/></StyledClose>
        </StyledModalContainer>
      </StyledModal>
    )
}

const MainImage = styled.div`
  cursor: pointer;
`

const ImageWrapper = styled.div`
  margin-bottom: 30px;
`;

export default function Project({data}: ProjectProps) {
  const [isClient, setIsClient] = useState(false);
  const [activeImage, setActiveImage] = useState(0);
  const [isModalOpen, setIsmodalOpen] = useState(false);
  const project = data.allContentfulProjects.nodes[0] || { image: [] }
  const [firstImage, ...restImages] = project.image || []
  const isDt = useMediaQuery({minWidth: 960});
  const [leftCol, rightCol] = restImages.reduce((acc: any, image: any, i: number) => {
    const [left, rigth] = acc;
    return i % 2 === 0 ? [[...left, image], rigth] : [left, [...rigth, image]];
  }, [[], []]);


  useEffect(() => {
    setIsClient(true);
  }, []);

  function onImageCLick(index: number) {
    setActiveImage(index);
    setIsmodalOpen(true);
  }

  return (
    <Layout>
      <SEO title={project.projectName}/>
      <StyledGrid>
        <Row>
          {isDt && isClient ? (
            <>
              <Col large={12}>
                <Modal onSelect={setActiveImage} onClose={() => setIsmodalOpen(false)} isOpen={isModalOpen} index={activeImage} images={project.image} />
                {firstImage && <MainImage onClick={() => onImageCLick(0)}><Img fluid={firstImage.fluid} /></MainImage>}
                <ProjectName>{project.projectName}</ProjectName>
                <ProjectProperty>{`Location: ${project.location}`}</ProjectProperty>
                <ProjectProperty>{`Project Year: ${project.projectYear}`}</ProjectProperty>
                <ProjectProperty>{`Project Area: ${project.projectArea} sq.m`}</ProjectProperty>
              </Col>
              <Col large={6} noGuttersS>
                {leftCol.map((img: any, key: number) => 
                    <ImageWrapper onClick={() => onImageCLick((key + 1) * 2 - 1)} key={key}>
                      <StyledImage fluid={img.fluid}/>
                    </ImageWrapper>
                )}
              </Col>
              <Col large={6} noGuttersS>
                {rightCol.map((img: any, key: number) => 
                    <ImageWrapper onClick={() => onImageCLick((key + 1) * 2)} key={key}>
                      <StyledImage fluid={img.fluid}/>
                    </ImageWrapper>
                )}
              </Col>
            </>) :
            <Col>
              <Header>
                <ProjectName>{project.projectName}</ProjectName>
                <BackLink to="/projects/"><BackIcon /></BackLink>
              </Header>
              <ProjectProperty>{`Location: ${project.location}`}</ProjectProperty>
              <ProjectProperty>{`Project Year: ${project.projectYear}`}</ProjectProperty>
              <ProjectProperty>{`Project Area: ${project.projectArea} sq.m`}</ProjectProperty>
              {project.image.map((img: any, key: number) =>
                <StyledColMobile noGutters key={key}>
                  <StyledImage fluid={img.fluid} backgroundColor/>
                </StyledColMobile>)
              }
            </Col>
          }
        </Row>
      </StyledGrid>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
      allContentfulProjects(filter: {slug: {eq: $slug}}) {
        nodes {
          image {
            fluid(maxWidth: 900, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
            }
          }
          projectName
          projectYear(formatString: "")
          projectArea
          location
        }
      }
  }
`
